<template>
  <SharedButton
    class="s-btn-bg-primary"
    @click="handleClick"
  >
    {{ buttonText }}
  </SharedButton>
</template>

<script>
import SharedButton from "@/components/Page/Liff/Shared/Button.vue";
import liff from "@line/liff";

export default {
  components: { SharedButton },
  props: {
    buttonText: {
      type: String,
      default: "確認",
    },
    type: {
      type: String,
      default: "CLOSE",
    },
    buttonUrl: {
      type: String,
    },
    disableDefaultClick: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick() {
      if (this.disableDefaultClick) {
        this.$emit('custom-click');
        return;
      }

      this.goNext();
    },
    goNext() {
      switch (this.type) {
        case "OA":
          window.location.href = `${process.env.VUE_APP_API_BASE_URL}/${this.$route.params.orgCode}/liff/general/redirect-to-oa`;
          break;
        case "URL":
          window.location.href = this.buttonUrl;
          break;
        case "member-center":
          this.$router.push({ name: "LiffMemberCenterHome", query: this.$route.query });
          break;
        case "member-edit":
          this.$router.push({ name: "LiffMemberCenterEdit", query: this.$route.query });
          break;
        case "edit_done":
          this.$router.push({ name: "LiffMemberCenterEditDone", query: this.$route.query });
          break;
        case "welcome":
          this.$router.push({ name: "LiffRegisterWelcome", query: this.$route.query });
          break;
        case "thanks":
          this.$router.push({ name: "LiffRegisterThanks", query: this.$route.query });
          break;
        case "intro":
          this.$router.push({ name: "LiffRegisterIntro", query: this.$route.query });
          break;
        case "register":
          this.$router.push({ name: "LiffRegisterIndex", query: this.$route.query });
          break;
        case "CLOSE":
        default:
          // 只在 liff app 有用
          liff.closeWindow();
      }
    }
  },
};
</script>
