<template>
  <div :style="colorObject">
    <Header
      :page-title="getModuleConfig('page_title')"
      :page-image="getModuleConfig('page_image')"
      :page-kv-image="getModuleConfig('page_kv_image')"
      :header-section="getModuleConfig('header_section')"
      :meta="meta"
    />

    <div v-if="showPage" class="page">
      <div
        v-if="getModuleConfig('done_page.step_section.enable_step_section')"
        class="p-3"
      >
        <SharedStep :steps="getModuleConfig('done_page.step_section.steps')" />
      </div>

      <div v-if="getModuleConfig('done_page.form_title')" class="page__title">
        {{ getModuleConfig("done_page.form_title") }}
      </div>
      <div v-if="getModuleConfig('done_page.form_desc')" class="page__desc">
        {{ getModuleConfig("done_page.form_desc") }}
      </div>

      <div v-if="content" v-html="content"></div>
    </div>
    <div class="page__button s-space-y-4">
      <MemberButton
        v-if="showSuccessButton"
        :button-text="getModuleConfig('done_page.button_text')"
        :button-url="getModuleConfig('done_page.button_url')"
        :type="getModuleConfig('done_page.button_action')"
      ></MemberButton>
      <SharedButton
        class="s-btn-bg-secondary"
        @click="redirectToIntended"
        v-if="showRedirectButton"
      >
        {{ redirectActionConfig.button_text }}
      </SharedButton>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SharedButton from "@/components/Page/Liff/Shared/Button.vue";
import MemberButton from "@/components/Page/Liff/Shared/MemberButton.vue";
import axios from "axios";
import SharedStep from "@/components/Page/Liff/Shared/Steps";
import Header from "@/components/Page/Liff/Shared/HeaderV3";
import themeColor from "@/mixins/liff/themeColor";

const BUTTON_TYPE = {
  SUCCESS: "success_button",
  REDIRECT: "redirect_button",
};

export default {
  mixins: [themeColor],
  components: {
    SharedButton,
    SharedStep,
    Header,
    MemberButton
  },
  data() {
    return {
      content: null,
      themeConfigPage:'register'
    };
  },
  computed: {
    ...mapGetters({
      hasIntended: "route/hasIntended",
      meta: "liffGeneral/meta",
      themeConfig: "liffGeneral/themeConfig",
    }),

    redirectActionConfig() {
      return (
        this.getModuleConfig("done_page.redirect_action") || {
          enable: false,
          button_type: [],
        }
      );
    },
    redirectActionEnabled() {
      return this.hasIntended && this.redirectActionConfig.enable;
    },
    showSuccessButton() {
      return (
        !this.redirectActionEnabled ||
        this.redirectActionConfig.button_type.includes(BUTTON_TYPE.SUCCESS)
      );
    },
    showRedirectButton() {
      return (
        this.redirectActionEnabled &&
        this.redirectActionConfig.button_type.includes(BUTTON_TYPE.REDIRECT)
      );
    },
    skipWelcomePage() {
      const allowedButtonTypes = Object.values(BUTTON_TYPE);

      return (
        this.redirectActionEnabled &&
        this.redirectActionConfig.button_type.filter((type) =>
          allowedButtonTypes.includes(type)
        ).length === 0
      );
    },    
    showPage() {
      return this.getModuleConfig('done_page.step_section.enable_step_section')
        || this.getModuleConfig('done_page.form_title')
        || this.getModuleConfig('done_page.form_desc')
        || this.content
    },
  },
  async created() {
    if (this.skipWelcomePage) {
      await this.redirectToIntended();
    }
  },
  beforeMount() {
    this.fetchContent();
  },
  methods: {
    ...mapActions({
      intended: "route/intended",
    }),
    async fetchContent() {
      const contentUrl = this.getModuleConfig("done_page.content_url")
      if (!contentUrl) return 
      let response = await axios.get(contentUrl);
      this.content = response.data;
    },
    redirectToIntended() {
      this.intended();
    },
    getModuleConfig(key) {
      return this.$store.getters["liffModule/getConfig"]("member_edit", key);
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  max-width: 425px;
  margin: auto;
}
.item {
  display: flex;
  margin-bottom: 16px;
  &__text {
    color: var(--liff-primary_text_color);
    font-size: 15px;
    line-height: 24px;
    font-weight: 500;
    margin-left: 8px;
  }
}
</style>
